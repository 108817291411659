import { AppLocale, GenerateLocales } from '@hrk/types'

export interface IUseLocaleResult {
  pageLocale: AppLocale
  browserLocale: AppLocale
}

// const navigator: Navigator | undefined = typeof window && window ? window.navigator : undefined

const getLocaleFromBrowser = (): AppLocale => {
  if (typeof navigator === `undefined`) {
    return GenerateLocales.de
  }

  const lang = navigator && navigator.language && navigator.language.split('-')[0]
  if (!lang) return GenerateLocales.en

  switch (lang) {
    case GenerateLocales.de:
      return GenerateLocales.de
    default:
      return GenerateLocales.en
  }
}

const getLocaleFromLocation = (): AppLocale => {
  if (typeof document === 'undefined' || !document.location) {
    return GenerateLocales.de
  }
  const currentPath = document.location.pathname
  const languagePart = currentPath.split('/')[1]
  if (languagePart && languagePart.toLocaleLowerCase() === GenerateLocales.en) {
    return GenerateLocales.en
  }
  return GenerateLocales.de
}

export const useLocale = (): IUseLocaleResult => {
  const browserLocale = getLocaleFromBrowser()
  const pageLocale = getLocaleFromLocation()
  return { pageLocale, browserLocale }
}
