exports.components = {
  "component---src-page-generators-content-page-content-page-template-tsx": () => import("./../../../src/pageGenerators/contentPage/contentPageTemplate.tsx" /* webpackChunkName: "component---src-page-generators-content-page-content-page-template-tsx" */),
  "component---src-page-generators-hochschul-portrait-hochschul-portrait-template-tsx": () => import("./../../../src/pageGenerators/hochschulPortrait/hochschulPortraitTemplate.tsx" /* webpackChunkName: "component---src-page-generators-hochschul-portrait-hochschul-portrait-template-tsx" */),
  "component---src-page-generators-weiterbildungs-angebot-weiterbildungs-angebot-template-tsx": () => import("./../../../src/pageGenerators/weiterbildungsAngebot/weiterbildungsAngebotTemplate.tsx" /* webpackChunkName: "component---src-page-generators-weiterbildungs-angebot-weiterbildungs-angebot-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-code-en-tsx": () => import("./../../../src/pages/code.en.tsx" /* webpackChunkName: "component---src-pages-code-en-tsx" */),
  "component---src-pages-code-tsx": () => import("./../../../src/pages/code.tsx" /* webpackChunkName: "component---src-pages-code-tsx" */),
  "component---src-pages-compare-en-tsx": () => import("./../../../src/pages/compare.en.tsx" /* webpackChunkName: "component---src-pages-compare-en-tsx" */),
  "component---src-pages-data-privacy-en-tsx": () => import("./../../../src/pages/data-privacy.en.tsx" /* webpackChunkName: "component---src-pages-data-privacy-en-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-faq-en-tsx": () => import("./../../../src/pages/faq.en.tsx" /* webpackChunkName: "component---src-pages-faq-en-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-further-training-search-en-tsx": () => import("./../../../src/pages/further-training-search.en.tsx" /* webpackChunkName: "component---src-pages-further-training-search-en-tsx" */),
  "component---src-pages-higher-educations-institutes-search-en-tsx": () => import("./../../../src/pages/higher-educations-institutes-search.en.tsx" /* webpackChunkName: "component---src-pages-higher-educations-institutes-search-en-tsx" */),
  "component---src-pages-hochschulsuche-tsx": () => import("./../../../src/pages/hochschulsuche.tsx" /* webpackChunkName: "component---src-pages-hochschulsuche-tsx" */),
  "component---src-pages-interest-test-en-tsx": () => import("./../../../src/pages/interest-test.en.tsx" /* webpackChunkName: "component---src-pages-interest-test-en-tsx" */),
  "component---src-pages-knowledge-areas-en-tsx": () => import("./../../../src/pages/knowledge-areas.en.tsx" /* webpackChunkName: "component---src-pages-knowledge-areas-en-tsx" */),
  "component---src-pages-link-en-tsx": () => import("./../../../src/pages/link.en.tsx" /* webpackChunkName: "component---src-pages-link-en-tsx" */),
  "component---src-pages-link-tsx": () => import("./../../../src/pages/link.tsx" /* webpackChunkName: "component---src-pages-link-tsx" */),
  "component---src-pages-search-en-tsx": () => import("./../../../src/pages/search.en.tsx" /* webpackChunkName: "component---src-pages-search-en-tsx" */),
  "component---src-pages-sitemap-en-tsx": () => import("./../../../src/pages/sitemap.en.tsx" /* webpackChunkName: "component---src-pages-sitemap-en-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-suche-tsx": () => import("./../../../src/pages/suche.tsx" /* webpackChunkName: "component---src-pages-suche-tsx" */),
  "component---src-pages-vergleich-tsx": () => import("./../../../src/pages/vergleich.tsx" /* webpackChunkName: "component---src-pages-vergleich-tsx" */),
  "component---src-pages-weiterbildung-interessentest-tsx": () => import("./../../../src/pages/weiterbildung-interessentest.tsx" /* webpackChunkName: "component---src-pages-weiterbildung-interessentest-tsx" */),
  "component---src-pages-weiterbildungs-interessentest-tsx": () => import("./../../../src/pages/weiterbildungs-interessentest.tsx" /* webpackChunkName: "component---src-pages-weiterbildungs-interessentest-tsx" */),
  "component---src-pages-weiterbildungssuche-tsx": () => import("./../../../src/pages/weiterbildungssuche.tsx" /* webpackChunkName: "component---src-pages-weiterbildungssuche-tsx" */),
  "component---src-pages-wissensbereiche-tsx": () => import("./../../../src/pages/wissensbereiche.tsx" /* webpackChunkName: "component---src-pages-wissensbereiche-tsx" */),
  "component---src-pages-yx-jjb-25-za-xn-3-yx-no-zx-jl-en-tsx": () => import("./../../../src/pages/YXJjb25zaXN3YXNoZXJl.en.tsx" /* webpackChunkName: "component---src-pages-yx-jjb-25-za-xn-3-yx-no-zx-jl-en-tsx" */),
  "component---src-pages-yx-jjb-25-za-xn-3-yx-no-zx-jl-tsx": () => import("./../../../src/pages/YXJjb25zaXN3YXNoZXJl.tsx" /* webpackChunkName: "component---src-pages-yx-jjb-25-za-xn-3-yx-no-zx-jl-tsx" */)
}

