import { useState } from 'react'

/** If in a browser, returns an instance of URLSearchParams for the current URL bar */
const getQuery = () => {
  if (typeof window !== 'undefined') {
    return new URLSearchParams(window.location?.search)
  }

  return new URLSearchParams()
}

/** Returns the first query parameter for the given key, or null */
const getQueryStringVal = (key: string): string | null => {
  return getQuery().get(key)
}

/** Returns all query parameter values for the given key, or an empty array */
const getQueryStringArray = (key: string): string[] => {
  return getQuery().getAll(key)
}

/** If in a browser, update the url with the given query params */
const updateUrl = (params: URLSearchParams, replace = true) => {
  if (typeof window !== 'undefined') {
    const { protocol, pathname, host } = window.location
    const queryString = params.toString()
    const queryWithQuestionMark = queryString !== '' ? `?${queryString}` : ''

    const newUrl = `${protocol}//${host}${pathname}${queryWithQuestionMark}`
    replace ? window.history.replaceState({}, '', newUrl) : window.history.pushState({}, '', newUrl)
  }
}

/** A Hook for a single value query parameter with the given key and default value */
export const useQueryParam = (
  key: string,
  defaultVal: string | null,
): [typeof defaultVal, React.Dispatch<React.SetStateAction<string | null>>] => {
  const [query, setQueryString] = useState(getQueryStringVal(key) ?? defaultVal)

  const setQuery = (newVal: string | null | ((prev: string | null) => string | null)) => {
    setQueryString(newVal)
    const params = getQuery()
    newVal = typeof newVal === 'function' ? newVal(null) : newVal

    if (newVal && newVal.trim() !== '') {
      // If the new value is non-empty, set the new value, replacing any existing occurrences
      params.set(key, newVal)
    } else {
      // Otherwise, just delete all existing values
      params.delete(key)
    }

    updateUrl(params)
  }

  return [query, setQuery]
}

/** A Hook for a query parameter with multiple values for the given key */
export const useQueryParams = (key: string): [string[], (val: string[]) => void] => {
  const [query, setQueryArray] = useState(getQueryStringArray(key))

  const setQuery = (newVals: string[]) => {
    setQueryArray(newVals)

    const params = getQuery()

    // First, delete all values with the given key
    params.delete(key)

    // Then, append the new values, if any
    if (newVals.length > 0) {
      newVals.forEach((val) => params.append(key, val))
    }

    updateUrl(params)
  }

  return [query, setQuery]
}
