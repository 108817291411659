import React, { createContext, useContext, useEffect, useState } from 'react'
import * as queryString from 'query-string'
import { useQueryParam } from '../hooks/useQueryParam'

const userCodeKey = 'USER_CODE'
const userCodeQueryStringKey = 'code'

export interface IUserCodeContext {
  userCode: string | null
  setUserCode: (userCode: string | null) => void
}

const defaultContext: IUserCodeContext = {
  userCode: null,
  setUserCode: () => {
    return
  },
}
export const UserCodeContext = createContext<IUserCodeContext>(defaultContext)

function getUserCode(): string | null {
  if (typeof window === 'undefined' || !window.location) {
    return null
  }
  const parsed = queryString.parse(window.location.search)
  const userCode = parsed[userCodeQueryStringKey] as string | null
  if (userCode) {
    localStorage.setItem(userCodeKey, userCode)
    return userCode
  }

  const userCodeFromStorage = localStorage.getItem(userCodeKey)
  return userCodeFromStorage
}

export const UserCodeContextProvider = (props: { children: JSX.Element }): JSX.Element => {
  const [internalUserCode, setInternalUserCode] = useState<string | null>(null)
  const [query, setQuery] = useQueryParam(userCodeQueryStringKey, '')

  function setUserCode(userCode: string | null) {
    if (!userCode) {
      localStorage.removeItem(userCodeKey)
      setQuery('')
      setInternalUserCode(null)
    } else {
      localStorage.setItem(userCodeKey, userCode)
      setQuery(userCode)
      setInternalUserCode(userCode)
    }
  }

  useEffect(() => {
    const userCode = getUserCode()
    setInternalUserCode(userCode)
  }, [])

  useEffect(() => {
    const userCode = getUserCode()
    setInternalUserCode(userCode)
  }, [query])

  return (
    <UserCodeContext.Provider value={{ userCode: internalUserCode, setUserCode }}>
      <>{props.children}</>
    </UserCodeContext.Provider>
  )
}

export const useUserCodeContext = (): IUserCodeContext => {
  return useContext<IUserCodeContext>(UserCodeContext)
}
