module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"/api/graphql"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","locale":"de","url":"https://hoch-und-weit.de","site_name":"hoch und weit"},"twitter":{"handle":"@handle","site":"@site","cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"hoch und weit","short_name":"hoch und weit","start_url":"/","background_color":"#E9E9E9","theme_color":"#990000","display":"standalone","icon":"src/images/BM_schattiert_RGB.svg","icon_options":{"purpose":"any maskable"},"description":"Das Weiterbildungsangebot Portal der Hochschulen.","lang":"de","legacy":true,"localize":[{"start_url":"/en/","lang":"en","name":"hoch und weit","short_name":"hoch und weit","description":"The higher-educations-institutes portal of for further trainings."}],"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ef01d77fc0148f9bf8c0aaaa14fb5868"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
