/* eslint-disable */
import React from 'react'
import { UserCodeContextProvider } from './src/context/UserCodeContext'
import { LayoutContextProvider } from './src/context/LayoutContext'
// import '@fontsource/open-sans'
import '@fontsource/source-serif-pro'
export const wrapRootElement = ({ element }) => {
  return (
    <LayoutContextProvider>
      <UserCodeContextProvider>{element}</UserCodeContextProvider>
    </LayoutContextProvider>
  )
}
